@import "~antd/dist/antd.dark.less";
@import "./ant-custom.less";

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  --row-highlight: @background-color-base;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.ant-layout-header {
  align-items: center;
  height: 42px;
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 64px;
  display: flex;
}

.App-Bar-left {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
}

.App-Bar-right {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-self: flex-end;
}

.wallet-wrapper {
  padding-left: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.wallet-key {
  padding: 0.1rem 0.5rem 0.1rem 0.7rem;
  margin-left: 0.3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}

em {
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}

.wallet-key {
  padding: 0.1rem 0.5rem 0.1rem 0.7rem;
  margin-left: 0.3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.ant-notification {
  a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.ant-layout-content {
  display: flex;
  overflow: auto;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card-fill {
  height: 100%;
}

.ant-card {
  background: rgba(0, 0, 0, 0.5);
  // border-radius: 8px;
}

.card-row {
  box-sizing: border-box;
  margin: 5px 0px;
  min-width: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .card-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    text-align: left;
    margin: 0px;
    min-width: 0px;
    font-size: 14px;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .small {
    font-size: 11px;
  }
}

.token-input {
  display: flex;
  align-items: center;
  border: 1px solid grey;
  padding: 0px 10px;
  margin: 5px 0px;
}

.token-balance {
  margin-left: auto;
  margin-right: 5px;
  color: @text-color-secondary;
}

[class="ant-layout-header"] {
  height: 16px !important;
}

@media only screen and (max-width: 600px) {
  .exchange-card {
    width: 360px;
  }
}

@primary-color: #2abdd2;